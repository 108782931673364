export default function Screen({ children, backgroundImage, className = '' }) {
  return (
    <div
      className={`fixed left-1/2 -translate-x-1/2 top-0 w-[800px] max-w-full h-screen overflow-hidden bg-cover bg-center ${className}`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {children}
    </div>
  );
}

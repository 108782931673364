import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'redux/reducers';

const configureStore = () => {
  const middlewares = [thunk];
  const composeEnahncers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enchancers = composeEnahncers(applyMiddleware(...middlewares));
  const store = createStore(rootReducer(), enchancers);

  return store;
};

export default configureStore;

import { PopupTypes } from 'helper/constant';
import BG_BENAR from 'images/pop-up-benar.png';
import BG_SALAH from 'images/pop-up-salah.png';
import BG_WAKTU_HABIS from 'images/pop-up-waktu-habis.png';
import BG from 'images/bg-popup-benar.png';
import { generateImgUrl } from 'helper/methods';
import { useSelector } from 'react-redux';
import WrongAnswerSound from './WrongAnswerSound';
import RightAnswerSound from './RightAnswerSound';

export default function PopupAnswer({
  type = PopupTypes.benar,
  score = 0,
  onClose,
  isActive,
}) {
  const { dataQuiz } = useSelector((state) => state.quiz);
  const renderBG = () => {
    if (type === PopupTypes.salah) return BG_SALAH;
    if (type === PopupTypes.waktuHabis) return BG_WAKTU_HABIS;
    return BG_BENAR;
  };

  return (
    <div
      className={`fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-70 flex items-center justify-center overflow-hidden ${
        isActive ? '' : 'pointer-events-none opacity-0'
      }`}
    >
      {type === PopupTypes.benar ? (
        <RightAnswerSound isAppear={isActive} />
      ) : (
        <WrongAnswerSound isAppear={isActive} />
      )}

      <div
        className='absolute left-0 top-0 right-0 bottom-0 bg-cover bg-center'
        style={
          type === PopupTypes.benar ? { backgroundImage: `url(${BG})` } : {}
        }
      ></div>
      {type === PopupTypes.benar ? (
        <div
          className={`absolute left-0 bottom-0 h-1/2 w-full bg-gradient-to-t from-[#50D600] to-transparent`}
        ></div>
      ) : (
        <div
          className={`absolute left-0 bottom-0 h-1/2 w-full bg-gradient-to-t from-[#D60F0E] to-transparent`}
        ></div>
      )}
      <div
        className={`relative duration-300 ${
          isActive
            ? type !== PopupTypes.benar && 'shake'
            : 'translate-y-8 scale-75'
        }`}
      >
        <img src={renderBG()} width={327} alt='NOVO-NORDISK' />
        <div className='absolute -ml-1 bottom-[26%] left-1/2 -translate-x-1/2 w-full text-center'>
          <span className='text-[#001965] font-black text-2xl'>
            {score} PTS
          </span>
        </div>
        <div
          onClick={onClose}
          className='absolute bottom-[10%] left-1/2 -translate-x-1/2 w-[245px] max-w-full active:scale-95'
        >
          <img
            src={generateImgUrl('btn-popup-selanjutnya', dataQuiz?.categoryId)}
            width={245}
            alt='NOVO-NORDISK'
          />
        </div>
      </div>
    </div>
  );
}

import { useDispatch, useSelector } from 'react-redux';
import BtnHint from './BtnHint';
import ProgressBar from './ProgressBar';
import { Scene } from 'helper/constant';
import { setHint } from 'redux/actions/hintAction';

export default function TopBar() {
  const dispatch = useDispatch();
  const { dataQuiz } = useSelector((state) => state.quiz);
  const { dataHint } = useSelector((state) => state.hint);
  const { activeScene } = useSelector((state) => state.scene);
  if (activeScene === Scene.quiz)
    return (
      <div className='p-[18px] w-[386px] max-w-full mx-auto flex items-start'>
        <ProgressBar
          length={dataQuiz.length}
          activeQuestion={dataQuiz.activeQuestion + 1}
        />
        <div className='ml-2'>
          <BtnHint
            disabled={dataHint.quota < 1}
            onClick={() => {
              dataQuiz.list[dataQuiz.activeQuestion].Answers.forEach((d) => {
                if (d.isTrue) dispatch(setHint(d.id));
              });
            }}
          />
        </div>
      </div>
    );
}

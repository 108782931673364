import axios from 'axios';

// const API = process.env.api;

const API = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'x-api-key': process.env.REACT_APP_SECRET_KEY,
  },
});

export const getCategory = () => API.get('/v1/categories');
export const getQuestions = (categoryId) =>
  API.get(`/v1/questions/${categoryId}`);
export const getLeaderboard = (userEmail) =>
  API.get(`/v1/submissions/leaderboard?email=${userEmail}`);
export const postScore = (body) => API.post('/v1/submissions', body);

import HAPPY from 'images/emoji-happy.png';
import PANIC from 'images/emoji-panic.png';
import SAD from 'images/emoji-sad.png';
import CRY from 'images/emoji-cry.png';
import DOT from 'images/dot-timer.png';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTimesUp } from 'redux/actions/quizAction';

export default function Emoji() {
  const dispatch = useDispatch();
  const numb = useRef();
  const face = useRef();
  const { timer } = useSelector((state) => state.quiz);
  // const [expression, setExpression] = useState(HAPPY);
  const arrExpression = [HAPPY, PANIC, SAD, CRY];

  useEffect(() => {
    if (timer) {
      let i = 0;
      let counter = 4;
      numb.current.innerText = counter;
      face.current.setAttribute('src', arrExpression[0]);
      numb.current.classList.add('rotate-rvrs');
      let timer1 = setInterval(() => {
        if (i < 3) {
          i++;
          face.current.setAttribute('src', arrExpression[i]);
          // setExpression(arrExpression[i]);
        } else {
          face.current.setAttribute('src', arrExpression[3]);
          // setExpression(arrExpression[3]);
          clearInterval(timer1);
          dispatch(setTimesUp());
        }
        counter--;
        numb.current.innerText = counter;
      }, 2000);
      return () => {
        clearInterval(timer1);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);
  return (
    <div className='smiley-counter relative w-24 border-[8px] border-white rounded-full mx-auto'>
      <div className='absolute left-0 top-0 w-full h-full bg-white rounded-full'></div>
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <svg
          version='1.1'
          id='Layer_1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='-10 -10 532 532'
          style={{ width: '98px', height: '98px' }}
          className='-scale-x-100'
        >
          <circle className='bg-path' cx='255' cy='255' r='255' transform='rotate(-90, 255,255)' />
          <circle
            className={`path ${timer ? 'animate' : ''}`}
            cx='255'
            cy='255'
            r='255'
            transform='rotate(-90, 255,255)'
          />
        </svg>
      </div>
      <img ref={face} className='relative' src={arrExpression[0]} alt='NOVO-NORDISK' />
      <div
        className={`absolute origin-bottom rotate-0 left-1/2 top-0 w-4 h-1/2 -translate-x-1/2 ${timer ? 'dot' : ''}`}
      >
        <div className='absolute -top-4'>
          <img src={DOT} width={20} height={20} alt='NOVO-NORDISK' />
          <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  -mt-[0.5px] -ml-[0.5px]'>
            <div ref={numb} className='duration-0 relative text-[10px] text-white font-apis font-black'>
              0
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

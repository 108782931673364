import { useEffect } from 'react';
import useSound from 'use-sound';
import SOUND from 'audio/wrong-answer.mp3';

export default function WrongAnswerSound({ isAppear }) {
  const [play] = useSound(SOUND, { volume: 0.5 });
  useEffect(() => {
    if (isAppear) {
      play();
    }
  }, [isAppear, play]);
}

import IMG_BTN_1 from 'images/btn-answer-1.png';
import IMG_BTN_2 from 'images/btn-answer-2.png';
import IMG_BTN_SELENCTED from 'images/btn-answer-green.png';
import IMG_BTN_HINT from 'images/btn-answer-orange.png';
import ICON_CHECK_GREEN from 'images/check-answer-green.png';
import ICON_CHECK_ORANGE from 'images/check-answer-orange.png';

export default function BtnAnswer({
  index,
  id,
  isSelected,
  text,
  onClick,
  isHint,
}) {
  const renderBG = () => {
    if (isSelected) return IMG_BTN_SELENCTED;
    if (isHint) return IMG_BTN_HINT;
    if (index > 1) return IMG_BTN_2;

    return IMG_BTN_1;
  };

  return (
    <div
      id={id}
      className='relative text-center w-[316px] max-w-full mx-auto'
      onClick={onClick}
    >
      <img
        className='inline-block'
        src={renderBG()}
        width={316}
        height={48}
        alt='NOVO-NORDISK'
      />
      {(isSelected || isHint) && (
        <img
          src={isSelected ? ICON_CHECK_GREEN : ICON_CHECK_ORANGE}
          alt='NOVO-NORDISK'
          width={15}
          height={15}
          className='absolute -top-1 -right-1'
        />
      )}
      <div className='absolute left-0 top-0 w-full h-full flex justify-center items-center px-6'>
        <span className='text-white text-xs font-bold select-none'>{text}</span>
      </div>
    </div>
  );
}

import { Rank, Scene } from 'helper/constant';
import { useDispatch, useSelector } from 'react-redux';
import Screen from 'components/Screen';
import { changeScene } from 'redux/actions/sceneAction';
import Modal from 'components/Modal';
import { useEffect, useRef, useState } from 'react';
import { generateImgUrl } from 'helper/methods';
import GameOverSound from 'components/GameOverSound';
import { postScore } from 'api';
import { getData, getLeaderboard, setUserEmail } from 'redux/actions/quizAction';
import REFERENSI from 'images/btn-referensi.png';
import BERANDA from 'images/btn-beranda.png';
import Cookies from 'js-cookie';
import { resetHint } from 'redux/actions/hintAction';

export default function RankScene() {
  const dispatch = useDispatch();
  const inputField = useRef();
  const [popupInput, setPopupInput] = useState(false);
  const [popupReferensi, setPopupReferensi] = useState(false);
  const [email, setEmail] = useState(Cookies.get('usr') || '');
  const { score } = useSelector((state) => state.user);
  const { dataQuiz } = useSelector((state) => state.quiz);
  const { activeScene } = useSelector((state) => state.scene);

  useEffect(() => {
    if (activeScene === Scene.result) {
      setPopupInput(false);
    }
  }, [activeScene]);

  const renderCard = () => {
    let res;
    if (score < 70) {
      res = Rank.sedikitLagi;
    } else if (score < 80) {
      res = Rank.baik;
    } else if (score < 100) {
      res = Rank.istimewa;
    } else {
      res = Rank.sempurna;
    }
    switch (res) {
      case Rank.baik:
        return generateImgUrl('result-baik', dataQuiz?.categoryId);
      case Rank.sedikitLagi:
        return generateImgUrl('result-sedikitlagi', dataQuiz?.categoryId);
      case Rank.istimewa:
        return generateImgUrl('result-istimewa', dataQuiz?.categoryId);
      case Rank.sempurna:
        return generateImgUrl('result-sempurna', dataQuiz?.categoryId);
      default:
        return;
    }
  };

  const emailValidation = () => {
    // eslint-disable-next-line no-useless-escape
    // const pattern = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
    // const isValid = pattern.test(email);
    // return isValid;
    if (email.includes(' ')) return false;
    return true;
  };

  const handleSubmit = () => {
    inputField.current.classList.remove('shake');
    if (emailValidation()) {
      dispatch(setUserEmail(email));
      postScore({
        email,
        score,
      }).then(() => {
        dispatch(getLeaderboard(email)).then(() => {
          Cookies.set('usr', email);
          dispatch(changeScene(Scene.leaderboard));
        });
      });
    } else {
      inputField.current.classList.add('shake');
      inputField.current.focus();
      setTimeout(() => {
        inputField.current.classList.remove('shake');
      }, 300);
    }
  };

  const handleOpenPopup = () => {
    setPopupInput(true);
    inputField.current.focus();
  };

  if (activeScene === Scene.result)
    return (
      <Screen>
        <GameOverSound isAppear={activeScene === Scene.result} />
        <div className='relative px-7 my-8'>
          <div className='responsive-handler'>
            <div className='desk-bg-result relative w-[343px] max-w-full mx-auto'>
              <img src={renderCard()} width={343} height={507} alt='NOVO_NORDISK' className='desk-orang' />
              <div className='result-score absolute ml-3 left-1/2 -translate-x-1/2 top-[61%]'>
                <span className='font-black text-base text-white'>{score} PTS</span>
              </div>
            </div>
          </div>
          <div className='btn-result-selanjutnya mt-6 active:scale-95' onClick={handleOpenPopup}>
            <img
              className='mx-auto'
              src={generateImgUrl('btn-selanjutnya-long', dataQuiz?.categoryId)}
              width={343}
              height={48}
              alt='NOVO-NORDISK'
            />
          </div>
          <div className='btn-result-group flex relative w-[343px] max-w-full mx-auto justify-between'>
            <div className='text-center mt-3' onClick={() => setPopupReferensi(true)}>
              <img src={REFERENSI} alt='NOVO-NORDISK' className='btn-split mx-auto' width={166} height={44} />
            </div>
            <div
              className='text-center mt-3'
              onClick={() => {
                dispatch(changeScene(Scene.onBoarding));
                dispatch(getData());
                dispatch(resetHint());
              }}
            >
              <img src={BERANDA} alt='NOVO-NORDISK' className='btn-split mx-auto' width={166} height={44} />
            </div>
          </div>
        </div>
        <Modal isActive={popupReferensi} onClose={() => setPopupReferensi(false)}>
          <div className='desk-referensi relative w-[327px] max-w-full mx-auto mt-[5%]'>
            <img
              className='bg mx-auto'
              src={generateImgUrl('popup-referensi', dataQuiz?.categoryId)}
              alt='NOVO-NORDISK'
              width={327}
            />
            <div className='referensi-scroller absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 h-[70%] mt-4 w-[78%] overflow-auto'>
              <div className='referensi-text relative text-[10px] pr-2 text-[#012070] leading-relaxed'>
                <ul className='[&>li]:list-disc [&>li]:mb-2 pl-3 break-all'>
                  <li>
                    Centers for Disease Control and Prevention. (2021). Benefits of Healthy Eating. Benefits of Healthy
                    Eating | Nutrition | DNPAO | CDC
                  </li>
                  <li>
                    Centers for Disease Control and Prevention. (2023). Health Benefits of Physical Activity for
                    Children, Adults, and Adults 65 and Older. Health Benefits of Physical Activity for Children,
                    Adults, and Adults 65 and Older | Physical Activity | CDC
                  </li>
                  <li>
                    Centers for Disease Control and Prevention. (2022). Tips for Better Sleep
                    <a
                      href='https://www.cdc.gov/sleep/about_sleep/sleep_hygiene.html#:~:text=Be%20consistent.,smart%20phones%2C%20from%20the%20bedroom'
                      rel='noreferrer'
                      target='_blank'
                    >
                      https://www.cdc.gov/sleep/about_sleep/sleep_hygiene.html#:~:text=Be%20consistent.,smart%20phones%2C%20from%20the%20bedroom
                    </a>
                  </li>
                  <li>
                    Centers for Disease Control and Prevention. (2023). Tips to Support Healthy Routines for Children
                    and Teens.
                    <a href='https://www.cdc.gov/healthyweight/children/index.html' target='_blank' rel='noreferrer'>
                      https://www.cdc.gov/healthyweight/children/index.html
                    </a>
                  </li>
                  <li>
                    IDAI. (2014). Diagnosis, Tata Laksana dan Pencegahan Obesitas pada Anak dan Remaja. UKK Nutrisi dan
                    Penyakit Metabolik.
                    <a
                      href='https://www.idai.or.id/professional-resources/pedoman-konsensus/diagnosis-tata-laksana-dan-pencegahan-obesitas-pada-anak-dan-remaja'
                      target='_blank'
                      rel='noreferrer'
                    >
                      https://www.idai.or.id/professional-resources/pedoman-konsensus/diagnosis-tata-laksana-dan-pencegahan-obesitas-pada-anak-dan-remaja
                    </a>
                  </li>
                  <li>
                    Kanellopoulou A, Notara V, Magriplis E, et al. (2021). Sleeping patterns and childhood obesity: an
                    epidemiological study in 1,728 children in Greece. J Clin Sleep Med. 2021;17(5):1093–1101
                  </li>
                  <li>
                    Kementerian Kesehatan Republik Indonesia. (2022). Apa yang Dilakukan jika Anda Gemuk.
                    <a
                      href='https://p2ptm.kemkes.go.id/infographic-p2ptm/obesitas/apa-yang-dilakukan-jika-anda-gemuk-obesitas'
                      target='_blank'
                      rel='noreferrer'
                    >
                      https://p2ptm.kemkes.go.id/infographic-p2ptm/obesitas/apa-yang-dilakukan-jika-anda-gemuk-obesitas
                    </a>
                  </li>
                  <li>
                    Lijuan Xiu, Mirjam Ekstedt, Maria Hagströmer, Oliviero Bruni, Linnea Bergqvist-Norén, Claude Marcus;
                    Sleep and Adiposity in Children From 2 to 6 Years of Age. Pediatrics March 2020; 145 (3): e20191420.
                    10.1542/peds.2019-1420
                  </li>
                  <li>
                    O’Malley G, Thivel D (2015). Physical Activity And Play In Children Who Are Obese. In M.L. Frelut
                    (Ed.), The ECOG’s eBook on Child and Adolescent Obesity. Retrieved from ebook.ecog-obesity.eu
                  </li>
                  <li>
                    Papatriantafyllou E, Efthymiou D, Zoumbaneas E, Popescu CA, Vassilopoulou E. (2022). Sleep
                    Deprivation: Effects on Weight Loss and Weight Loss Maintenance. Nutrients. Apr 8;14(8):1549. doi:
                    10.3390/nu14081549. PMID: 35458110; PMCID: PMC9031614.
                  </li>
                  <li>
                    Sarah E. Hampl, Sandra G. Hassink, Asheley C. Skinner, et al. (2023). Clinical Practice Guideline
                    for the Evaluation and Treatment of Children and Adolescents With Obesity. Pediatrics February 2023;
                    151 (2): e2022060640. 10.1542/peds.2022-060640.
                  </li>
                  <li>
                    Unicef. (2022). Landscape Analysis of Overweight and Obesity in Indonesia.
                    <a
                      href='https://www.unicef.org/indonesia/media/15481/file/Landscape%20analysis%20of%20overweight%20and%20obesity%20in%20Indonesia.pdf'
                      target='_blank'
                      rel='noreferrer'
                    >
                      https://www.unicef.org/indonesia/media/15481/file/Landscape%20analysis%20of%20overweight%20and%20obesity%20in%20Indonesia.pdf
                    </a>
                  </li>
                  <li>
                    WHO. (2000). Obesity: preventing and managing the global epidemic.
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.who.int/nutrition/publications/obesity/WHO_TRS_894/en/.'
                    >
                      , https://www.who.int/nutrition/publications/obesity/WHO_TRS_894/en/.
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <button
              onClick={() => setPopupReferensi(false)}
              className='referensi-close absolute right-1 top-[7%] w-7 h-7'
            >
              &nbsp;
            </button>
          </div>
        </Modal>
        <Modal isActive={popupInput}>
          <div className='popup-input-name relative w-[327px] max-w-full mx-auto mt-[30%]'>
            <img
              className='bg-input'
              src={generateImgUrl('popup-info-kamu', dataQuiz?.categoryId)}
              alt='NOVO-NORDISK'
              width={327}
            />
            <div className='field-input absolute left-1/2 -translate-x-1/2 top-[44.5%] w-[74%] ml-2'>
              <input
                className='text-xl bg-transparent outline-none'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                ref={inputField}
              />
            </div>
            <div
              className='absolute left-1/2 -translate-x-1/2 bottom-[11%] w-[74%] -ml-[2px] active:scale-95'
              onClick={handleSubmit}
            >
              <img
                className='mx-auto'
                src={generateImgUrl('btn-popup-selanjutnya', dataQuiz?.categoryId)}
                alt='NOVO-NORDISK'
              />
            </div>
          </div>
        </Modal>
      </Screen>
    );
}

import { Category } from './constant';
// import SOUND_BLUE from 'audio/bgm--blue.mp3';
// import SOUND_YELLOW from 'audio/bgm--yellow.mp3';
// import SOUND_GREEN from 'audio/bgm--green.mp3';

export const generateImgUrl = (audioName, categoryId) => {
  let temp = Category.blue;
  if (categoryId === 2) temp = Category.yellow;
  if (categoryId === 3) temp = Category.green;

  // console.log(`${window.location.origin}/images/${audioName}--${temp}.png`);

  return require(`images/${audioName}--${temp}.png`);
};

export const generateAudioUrl = (audioName, categoryId) => {
  let temp;

  if (categoryId === 1) temp = Category.blue;
  if (categoryId === 2) temp = Category.yellow;
  if (categoryId === 3) temp = Category.green;

  // console.log(`${window.location.origin}/images/${audioName}--${temp}.png`);

  if (temp) {
    return require(`audio/${audioName}--${temp}.mp3`);
  }
};

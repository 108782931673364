import PanelQuestion from 'components/PanelQuestion';
import PopupAnswer from 'components/PopupAnswer';
import Screen from 'components/Screen';
import TopBar from 'components/TopBar';
import { v4 } from 'uuid';
import { PopupTypes, Scene } from 'helper/constant';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextQuestion,
  setScore,
  startTimer,
  stopTimer,
} from 'redux/actions/quizAction';
import { changeScene } from 'redux/actions/sceneAction';
import PopupName from 'components/PopupName';
import { setHint } from 'redux/actions/hintAction';

export default function Quiz() {
  const dispatch = useDispatch();
  const { activeScene } = useSelector((state) => state.scene);
  const { score } = useSelector((state) => state.user);
  const { dataQuiz, timesUp } = useSelector((state) => state.quiz);
  const [popupActive, setPopupActive] = useState(false);
  const [popupType, setPopupType] = useState();
  const [popupName, setPopupName] = useState(false);

  useEffect(() => {
    dispatch(startTimer());
  }, [dispatch]);

  useEffect(() => {
    if (timesUp) handleOpenPopup(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesUp]);

  const renderQuestions = () => {
    const elm = [];
    if (dataQuiz.list.length) {
      if (dataQuiz.activeQuestion === dataQuiz.list.length) {
        // handle back from result/rank
        // setPopupName(true);
        // elm.push(
        //   <div key={v4()}>
        //     <PanelQuestion
        //       id={dataQuiz.list[dataQuiz.activeQuestion - 1].id}
        //       question={dataQuiz.list[dataQuiz.activeQuestion - 1].questionText}
        //       answers={dataQuiz.list[dataQuiz.activeQuestion - 1].Answers}
        //       onSelected={handleOpenPopup}
        //       correctId={dataQuiz.list[dataQuiz.activeQuestion - 1]?.key}
        //     />
        //   </div>,
        // );
      } else {
        dataQuiz.list.forEach((d, idx) => {
          const isActive = idx === dataQuiz.activeQuestion;
          elm.push(
            <div key={v4()} className={`${isActive ? 'block' : 'hidden'}`}>
              <PanelQuestion
                id={d.id}
                question={d.questionText}
                answers={d.Answers}
                onSelected={handleOpenPopup}
              />
            </div>,
          );
        });
      }
    }
    return elm;
  };

  const handleOpenPopup = (res) => {
    setTimeout(() => {
      if (timesUp) {
        setPopupType(PopupTypes.waktuHabis);
        dispatch(setScore(-5));
      } else if (res) {
        setPopupType(PopupTypes.benar);
        dispatch(setScore(10));
      } else {
        setPopupType(PopupTypes.salah);
        dispatch(setScore(-5));
      }
      dispatch(stopTimer());
      setPopupActive(true);
    }, 300);
  };

  const handleClosePopup = () => {
    setPopupActive(false);
    dispatch(startTimer());
    dispatch(setHint(0));
    if (dataQuiz.activeQuestion + 1 === dataQuiz.length) {
      dispatch(changeScene(Scene.result));
    }
    dispatch(nextQuestion(dataQuiz.activeQuestion + 1));
  };
  if (activeScene === Scene.quiz)
    return (
      <Screen>
        <TopBar />
        {dataQuiz && renderQuestions()}
        <PopupAnswer
          score={score}
          isActive={popupActive}
          type={popupType}
          onClose={handleClosePopup}
        />
        <PopupName isActive={popupName} onClose={() => setPopupName(false)} />
      </Screen>
    );
}

import IMG_STRIP from 'images/loading-strip.png';

export default function ProgressBar({ length, activeQuestion }) {
  return (
    <div className='relative w-full flex items-center'>
      <div className='relative w-full mr-2'>
        <div className='absolute w-[calc(100%+1px)] h-[29px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-gradient-to-r rounded-full from-white to-[#001965]'></div>
        <div className='relative h-7 rounded-full w-full bg-[#001965] p-px'>
          <div
            className='h-[26px] max-w-full rounded-full bg-center bg-repeat-x overflow-hidden bg-[#91A3E2] shadow-inner shadow-white'
            style={{
              backgroundImage: `url(${IMG_STRIP})`,
              width: activeQuestion
                ? `${(activeQuestion / length) * 100}%`
                : '30px',
            }}
          ></div>
        </div>
      </div>
      <div className='text-base font-bold'>
        {activeQuestion}/{length}
      </div>
    </div>
  );
}

export default function Modal({ isActive, onClose, children }) {
  return (
    <div
      className={`fixed left-0 top-0 w-full h-full z-10 overflow-auto py-5 px-6 ${
        isActive ? '' : 'opacity-0 pointer-events-none'
      }`}
    >
      <div
        className='fixed left-0 top-0 w-full h-full bg-black bg-opacity-50'
        onClick={onClose}
      ></div>
      <div className='relative w-[460px] max-w-full mx-auto'>{children}</div>
    </div>
  );
}

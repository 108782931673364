const CATEGORY_BLUE = 'blue';
const CATEGORY_YELLOW = 'yellow';
const CATEGORY_GREEN = 'green';

export const Category = {
  blue: CATEGORY_BLUE,
  yellow: CATEGORY_YELLOW,
  green: CATEGORY_GREEN,
};

export const Scene = {
  onBoarding: 'ONBOARDING',
  quiz: 'QUIZ',
  result: 'RESULT',
  leaderboard: 'LEADERBOARD',
};

export const Expression = {
  happy: 'HAPPY',
  sad: 'SAD',
  panic: 'PANIC',
  cry: 'CRY',
};

export const Rank = {
  sedikitLagi: 'SEDIKIT_LAGI',
  baik: 'BAIK',
  istimewa: 'ISTIMEWA',
  sempurna: 'SEMPURNA',
};

export const PopupTypes = {
  benar: 'BENAR',
  salah: 'SALAH',
  waktuHabis: 'WAKTU_HABIS',
};

// export const ActionPage = {}

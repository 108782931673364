import ICON_LAMP from 'images/icon-lamp.png';
import ICON_LAMP_DISABLED from 'images/icon-lamp--disabled.png';

export default function BtnHint({ onClick, disabled }) {
  return (
    <div
      className={`relative w-[46px] text-center ${
        disabled ? 'pointer-events-none' : ''
      }`}
      onClick={onClick}
    >
      <img
        className='mx-auto'
        src={disabled ? ICON_LAMP_DISABLED : ICON_LAMP}
        width={28}
        height={28}
        alt='NOVO-NORDISK'
      />
      <div className='text-[10px] text-[#012070] '>Bantuan jawaban</div>
    </div>
  );
}

import { generateImgUrl } from 'helper/methods';
import { useSelector } from 'react-redux';

export default function PopupName({ isActive, onClose }) {
  const { dataQuiz } = useSelector((state) => state.quiz);
  return (
    <div
      className={`fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-70 flex items-center justify-center overflow-hidden ${
        isActive ? '' : 'pointer-events-none opacity-0'
      }`}
    >
      <div className='relative w-[327px] max-w-full'>
        <img
          src={generateImgUrl('popup-info-kamu', dataQuiz?.categoryId)}
          width={327}
          height={48}
          alt='NOVO-NORDISK'
        />
        <div
          className='absolute left-1/2 -translate-x-1/2 bottom-[10%] active:scale-90'
          onClick={onClose}
        >
          <img
            width={245}
            src={generateImgUrl('btn-popup-selanjutnya', dataQuiz?.categoryId)}
            alt='NOVO-NORDISK'
          />
        </div>
      </div>
    </div>
  );
}

import { useEffect } from 'react';
import useSound from 'use-sound';
import SOUND from 'audio/game-over.mp3';

export default function GameOverSound({ isAppear }) {
  const [play] = useSound(SOUND, { volume: 0.5 });
  useEffect(() => {
    if (isAppear) {
      play();
    }
  }, [isAppear, play]);
}

import Screen from 'components/Screen';
import { useSelector } from 'react-redux';
import Start from './Start';
import Intro from './Intro';
import { Scene } from 'helper/constant';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

export default function OnBoarding({ playAudio }) {
  const [activeScreen, setActiveScreen] = useState(0);
  const { activeScene } = useSelector((state) => state.scene);
  useEffect(() => {
    Cookies.remove('usr');
  }, []);
  useEffect(() => {
    if (activeScene === Scene.onBoarding) {
      setActiveScreen(0);
    }
  }, [activeScene]);
  if (activeScene === Scene.onBoarding)
    return (
      <Screen>
        <Start isActive={activeScreen === 1} />
        <Intro
          onChange={() => {
            setActiveScreen(1);
            playAudio();
          }}
          isActive={activeScreen === 0}
        />
      </Screen>
    );
}

import * as api from 'api';

import {
  DECREASE_HEART,
  GET_DATA,
  GET_LEADERBOARD,
  NEXT_QUESTION,
  RESET_QUESTION,
  SET_EMAIL,
  SET_SCORE,
  SET_TIMESUP,
  START_TIMER,
  STOP_TIMER,
} from 'redux/types';
// import DATA from 'data/collections.json';

export const getData = () => async (dispatch) => {
  // const random = Math.floor(Math.random() * 3);
  const dataCategory = await api.getCategory();
  const { data } = await api.getQuestions(dataCategory.data.data.id);
  const dataQuestion = {
    categoryId: dataCategory.data.data.id,
    listQuestion: data.data,
  };
  try {
    dispatch({ type: GET_DATA, data: dataQuestion });
  } catch (error) {
    throw error;
  }
};

export const startTimer = () => (dispatch) => {
  dispatch({ type: START_TIMER, data: true });
};

export const getLeaderboard = (userEmail) => async (dispatch) => {
  const { data } = await api.getLeaderboard(userEmail);
  dispatch({ type: GET_LEADERBOARD, data: data });
};

export const stopTimer = () => (dispatch) => {
  dispatch({ type: STOP_TIMER, data: false });
};

export const setTimesUp = () => (dispatch) => {
  dispatch({ type: SET_TIMESUP, data: true });
};

export const setUserEmail = (email) => (dispatch) => {
  dispatch({ type: SET_EMAIL, data: email });
};

export const resetQuestion = () => async (dispatch) => {
  try {
    dispatch({ type: RESET_QUESTION });
  } catch (error) {
    throw error;
  }
};

export const nextQuestion = (dataNext) => (dispatch) => {
  dispatch({ type: NEXT_QUESTION, data: dataNext });
};

export const setScore = (score) => (dispatch) => {
  dispatch({ type: SET_SCORE, data: score });
};

export const decreaseHeart = () => (dispatch) => {
  dispatch({ type: DECREASE_HEART });
};

import {
  GET_DATA,
  NEXT_QUESTION,
  RESET_QUESTION,
  START_TIMER,
  STOP_TIMER,
  SET_TIMESUP,
  GET_LEADERBOARD,
} from 'redux/types';

const initDataQuiz = {
  activeQuestion: 0,
  length: null,
  list: [],
  categoryId: null,
};

const quizReducer = (
  state = {
    dataQuiz: initDataQuiz,
    timer: false,
    timesUp: false,
    leaderboard: [],
    myRank: null,
  },
  { type, data },
) => {
  switch (type) {
    case GET_DATA:
      return {
        ...state,
        dataQuiz: {
          activeQuestion: 0,
          length: data.listQuestion.length,
          list: data.listQuestion,
          categoryId: data.categoryId,
        },
      };
    case SET_TIMESUP:
      return {
        ...state,
        timer: false,
        timesUp: data,
      };
    case STOP_TIMER:
    case START_TIMER:
      return {
        ...state,
        timer: data,
        timesUp: false,
      };
    case NEXT_QUESTION:
      return {
        ...state,
        dataQuiz: {
          ...state.dataQuiz,
          activeQuestion: data,
        },
      };
    case RESET_QUESTION:
      return {
        ...state,
        dataQuiz: {
          ...state.dataQuiz,
          activeQuestion: 0,
        },
      };
    case GET_LEADERBOARD:
      return {
        ...state,
        leaderboard: data.data,
        myRank: data.user,
      };
    default:
      return state;
  }
};

export default quizReducer;

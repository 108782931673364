import Screen from 'components/Screen';
import BG from 'images/bg-intro-blank.png';
import LOGO from 'images/logo-intro.png';
import B_BOOK from 'images/bubble-book.png';
import B_BANANA from 'images/bubble-banana.png';
import B_BROKOLI from 'images/bubble-brokoli.png';
import B_GLOBE from 'images/bubble-globe.png';
import B_HEART from 'images/bubble-heart.png';
import B_SPORT from 'images/bubble-sport.png';
import BTN from 'images/btn-mulai.png';
import GEMAS from 'images/logo-gemas.png';

export default function Intro({ isActive, onChange }) {
  return (
    <Screen
      backgroundImage={BG}
      className={
        isActive
          ? 'opacity-100'
          : 'translate-y-full duration-300 ease-in pointer-events-none'
      }
    >
      <div className='absolute left-1/2 w-[230px] top-16 -translate-x-1/2 flex items-center'>
        <img
          className='mr-4'
          width={230}
          height={85}
          src={LOGO}
          alt='NOVO-NORDISK'
        />
        {/* <img src={LOGO} width={78} height={55} alt='NOVO-NORDISK' /> */}
      </div>
      <div
        className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-64 max-w-[calc(100%-32px)] -mt-10 duration-300 ${
          isActive ? 'intro-active' : ''
        }`}
      >
        <img src={GEMAS} width={298} height={92} alt='NOVO-NORDISK' />
        <div className='fly absolute w-[44px] -right-8 -top-5'>
          <img src={B_BANANA} alt='NOVO-NORDISK' />
        </div>
        <div className='fly absolute w-[60px] -bottom-14 -right-8'>
          <img src={B_BOOK} alt='NOVO-NORDISK' />
        </div>
        <div className='fly absolute w-14 left-1/3 ml-5 -top-20 mt-2'>
          <img src={B_BROKOLI} alt='NOVO-NORDISK' />
        </div>
        <div className='fly absolute w-14 top-2/3 mt-4 left-1/3'>
          <img src={B_GLOBE} alt='NOVO-NORDISK' />
        </div>
        <div className='fly absolute w-16 -left-10 top-full -mt-3'>
          <img src={B_HEART} alt='NOVO-NORDISK' />
        </div>
        <div className='fly absolute w-16 -top-14 -left-3'>
          <img src={B_SPORT} alt='NOVO-NORDISK' />
        </div>
      </div>
      <button
        onClick={onChange}
        className='absolute px-2 w-[343px] max-w-full left-1/2 -translate-x-1/2 bottom-24'
      >
        <img src={BTN} alt='NOVO-NORDISK' />
      </button>
    </Screen>
  );
}

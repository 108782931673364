export const GET_DATA = 'GET_DATA';
export const SET_HINT = 'SET_HINT';
export const RESET_HINT = 'RESET_HINT';
export const CHANGE_SCENE = 'CHANGE_SCENE';
export const NEXT_QUESTION = 'NEXT_QUESTION';
export const RESET_QUESTION = 'RESET_QUESTION';
export const GET_LEADERBOARD = 'GET_LEADERBOARD';
export const SET_EMAIL = 'SET_EMAIL';

export const GET_SCORE = 'GET_SCORE';
export const SET_SCORE = 'SET_SCORE';
export const DECREASE_HEART = 'DECREASE_HEART';
export const START_TIMER = 'START_TIMER';
export const STOP_TIMER = 'STOP_TIMER';
export const SET_TIMESUP = 'SET_TIMESUP';

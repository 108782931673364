const {
  SET_SCORE,
  DECREASE_HEART,
  RESET_QUESTION,
  GET_DATA,
  SET_EMAIL,
} = require('redux/types');

const userReducer = (
  state = { score: 0, heart: 4, userEmail: null },
  { type, data },
) => {
  switch (type) {
    case SET_SCORE:
      return {
        ...state,
        score: state.score + data,
      };
    case SET_EMAIL:
      return {
        ...state,
        userEmail: data,
      };
    case DECREASE_HEART:
      return {
        ...state,
        heart: state.heart - 1,
      };
    case RESET_QUESTION:
    case GET_DATA:
      return {
        ...state,
        score: 0,
        heart: 4,
      };
    default:
      return state;
  }
};

export default userReducer;

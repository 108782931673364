import { combineReducers } from 'redux';
import quizReducer from './quiz';
import sceneReducer from './scene';
import userReducer from './user';
import hintReducer from './hint';

const rootReducer = () =>
  combineReducers({
    quiz: quizReducer,
    scene: sceneReducer,
    user: userReducer,
    hint: hintReducer,
  });

export default rootReducer;

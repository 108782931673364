import BG from 'images/panel-leaderboard-list.png';
import BG_YOU from 'images/panel-leaderboard-list--you.png';
import STAR from 'images/icon-star.png';
import MEDALS1 from 'images/medals-1.png';
import MEDALS2 from 'images/medals-2.png';
import MEDALS3 from 'images/medals-3.png';
export default function ItemList({ name, score, badge, isYou }) {
  const renderMedals = (medal) => {
    let icon = null;
    switch (medal) {
      case 1:
        icon = MEDALS1;
        break;
      case 2:
        icon = MEDALS2;
        break;
      case 3:
        icon = MEDALS3;
        break;
      default:
        break;
    }
    return icon;
  };

  const renderName = (str) => {
    // const temp = str.indexOf('@');
    // if (temp < 6) return `${name.substring(0, name.indexOf('@') - 1)}XXX`;
    // else
    if (name.length < 12) return name;
    return `${name.substring(0, 12)}...`;
  };

  return (
    <div className='btn-list relative w-[279px] max-w-full text-[#001965] mx-auto mb-1'>
      <img className='bg' src={isYou ? BG_YOU : BG} width={279} alt='NOVO-NORDISK' />
      <div className='absolute left-0 top-0 w-full h-full flex items-center pl-5 pr-7'>
        <div className='medals w-[21px] font-LG text-[#6B79A4] text-base mr-3'>
          {renderMedals(badge) ? <img src={renderMedals(badge)} alt='NOVO-NORDISK' /> : badge}
        </div>
        <div className='user-name relative text-sm w-32'>
          {renderName(name)}{' '}
          {isYou && (
            <span className='yours text-[12px] bg-[#231103] rounded-full text-white px-2 py-0 absolute -right-2 top-1/2 -translate-y-1/2'>
              Kamu
            </span>
          )}
        </div>

        <div className='star text-sm font-bold flex items-center w-24 justify-end'>
          <img className='mr-1' src={STAR} width={20} alt='NOVO-NORDISK' /> {score} PTS
        </div>
      </div>
    </div>
  );
}

import ItemList from 'components/ItemList';
import Screen from 'components/Screen';
import { v4 } from 'uuid';
import { Scene } from 'helper/constant';
import { generateImgUrl } from 'helper/methods';
import { useDispatch, useSelector } from 'react-redux';
import { resetHint } from 'redux/actions/hintAction';
import { getData, resetQuestion } from 'redux/actions/quizAction';
import { changeScene } from 'redux/actions/sceneAction';

export default function Leaderboard() {
  const dispatch = useDispatch();
  const { activeScene } = useSelector((state) => state.scene);
  const { score, userEmail } = useSelector((state) => state.user);
  const { dataQuiz, leaderboard, myRank } = useSelector((state) => state.quiz);

  const sortByScore = (a, b) => {
    return b.score - a.score;
  };

  const renderList = () => {
    const elm = [];
    let max = 6;
    leaderboard.sort(sortByScore);
    leaderboard.forEach((d, idx) => {
      if (idx < max) {
        if (d.email === userEmail) max = 7;
        elm.push(
          <ItemList
            isYou={d.email === userEmail}
            key={v4()}
            badge={idx + 1}
            name={d.email}
            score={d.score}
          />,
        );
      }
    });
    if (myRank.rank >= max) {
      elm.push(
        <ItemList
          isYou={true}
          key={v4()}
          badge={myRank.rank}
          name={myRank.email}
          score={myRank.score}
        />,
      );
    }
    return elm;
  };
  if (activeScene === Scene.leaderboard)
    return (
      <Screen>
        <div className='relative mx-auto w-[327px] max-w-full mt-6'>
          <img
            src={generateImgUrl('panel-leaderboard', dataQuiz?.categoryId)}
            alt='NOVO-NORDISK'
          />
          <div className='absolute left-0 top-0 w-full h-full pt-7 pb-12'>
            <div className='text-center text-[#001965] mb-4'>
              <div className=' text-sm'>Skor Akhir Kamu</div>
              <h2 className='text-[28px] font-LG leading-tight'>{score} PTS</h2>
            </div>
            <div className='overflow-auto'>{renderList()}</div>
            <div className='flex w-full justify-around absolute -bottom-2 px-4 left-0'>
              <div
                className='w-[132px] active:scale-95'
                onClick={() => {
                  dispatch(resetQuestion());
                  dispatch(resetHint());
                }}
              >
                <img
                  src={generateImgUrl('btn-mainlagi', dataQuiz?.categoryId)}
                  alt='NOVO-NORDISK'
                />
              </div>
              <div
                className='w-[132px] active:scale-95'
                onClick={() => {
                  dispatch(getData());
                  dispatch(resetHint());
                  dispatch(changeScene(Scene.onBoarding));
                }}
              >
                <img
                  src={generateImgUrl('btn-baru', dataQuiz?.categoryId)}
                  alt='NOVO-NORDISK'
                />
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
}

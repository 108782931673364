import Modal from 'components/Modal';
import Screen from 'components/Screen';
import { Scene } from 'helper/constant';
import { generateImgUrl } from 'helper/methods';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeScene } from 'redux/actions/sceneAction';
// import HOME from 'images/icon-home.png';

export default function Start({ isActive }) {
  const dispatch = useDispatch();
  const { dataQuiz } = useSelector((state) => state.quiz);
  const [modalActive, setModalActive] = useState(false);
  const handleChangeScene = () => {
    dispatch(changeScene(Scene.quiz));
  };
  return (
    <Screen backgroundImage={generateImgUrl('main-bg', dataQuiz?.categoryId)}>
      {/* <div className='absolute left-1/2 top-4 -translate-x-1/2 w-[400px] max-w-full'>
        <button className='relative left-4'>
          <img src={HOME} width={40} height={40} alt='NOVO-NORDISK' />
        </button>
      </div> */}
      <div className='absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 w-[400px] max-w-full'>
        <div
          className={`duration-1000 -mt-36 ${
            isActive && !modalActive
              ? 'scale-100 delay-300'
              : 'scale-0 opacity-0'
          }`}
        >
          <img
            src={generateImgUrl('title-light', dataQuiz?.categoryId)}
            alt='NOVO-NORDISK'
            className={`rotate `}
          />
        </div>
      </div>
      <div className='absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 -mt-20 w-[420px] max-w-[calc(100%-32px)]'>
        <img
          src={generateImgUrl('title-start-all', dataQuiz?.categoryId)}
          alt='NOVO-NORDISK'
          className={`duration-500 ${
            isActive && !modalActive ? 'scale-100 delay-150' : 'scale-0'
          }`}
        />
      </div>
      {!modalActive && (
        <button
          onClick={() => setModalActive(true)}
          className='absolute px-2 w-[343px] max-w-full left-1/2 -translate-x-1/2 bottom-24'
        >
          <img
            src={generateImgUrl('btn-mulai-main', dataQuiz?.categoryId)}
            alt='NOVO-NORDISK'
          />
        </button>
      )}

      <Modal isActive={modalActive} onClose={() => setModalActive(false)}>
        <div
          className={`relative w-[327px] max-w-full mx-auto top-8 duration-300 ${
            modalActive ? '' : 'opacity-0 translate-y-7 scale-75'
          }`}
        >
          <img
            src={generateImgUrl('cara-bermain', dataQuiz?.categoryId)}
            alt='NOVO-NORDISK'
          />
          <button
            onClick={handleChangeScene}
            className='absolute px-2 w-[245px] max-w-full left-1/2 -translate-x-1/2 bottom-10'
          >
            <img
              src={generateImgUrl(
                'btn-mulai-main-cara-bermain',
                dataQuiz?.categoryId,
              )}
              alt='NOVO-NORDISK'
            />
          </button>
        </div>
      </Modal>
    </Screen>
  );
}

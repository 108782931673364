import { Scene } from 'helper/constant';
import { CHANGE_SCENE, RESET_QUESTION } from 'redux/types';

const sceneReducer = (
  state = { activeScene: Scene.onBoarding },
  { type, data },
) => {
  switch (type) {
    case CHANGE_SCENE:
      return {
        ...state,
        activeScene: data,
      };
    case RESET_QUESTION:
      return {
        ...state,
        activeScene: Scene.quiz,
      };
    // case GET_DATA:
    //   return {
    //     ...state,
    //     activeScene: Scene.result,
    //   };
    default:
      return state;
  }
};

export default sceneReducer;

// import { Category } from 'helper/constant';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OnBoarding from './OnBoarding';
import Quiz from './Quiz';
import Leaderboard from './Leaderboard';
import Screen from 'components/Screen';
import { getData } from 'redux/actions/quizAction';
import RankScene from './Rank';
import { generateImgUrl } from 'helper/methods';

export default function App() {
  const dispatch = useDispatch();
  const [muted, setMute] = useState(true);
  const { dataQuiz } = useSelector((state) => state.quiz);
  const audio = useRef();

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  const handleToggle = () => {
    if (muted) {
      audio.current.play();
    } else {
      audio.current.pause();
    }
    setMute(!muted);
  };

  const renderAudio = () => {
    switch (dataQuiz?.categoryId) {
      case 1:
        return <source src={require('audio/bgm--blue.mp3')} type='audio/mp3' />;
      case 2:
        return (
          <source src={require('audio/bgm--yellow.mp3')} type='audio/mp3' />
        );
      case 3:
        return (
          <source src={require('audio/bgm--green.mp3')} type='audio/mp3' />
        );
      default:
        return;
    }
  };

  return (
    <Screen backgroundImage={generateImgUrl('main-bg', dataQuiz?.categoryId)}>
      <Leaderboard />
      <RankScene />
      <Quiz />
      <OnBoarding playAudio={handleToggle} />
      <audio ref={audio} loop>
        {/* <source
          src={generateAudioUrl('bgm', dataQuiz?.categoryId)}
          type='audio/mp3'
        /> */}
        {renderAudio()}
      </audio>
    </Screen>
  );
}

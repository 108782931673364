import IMG_PANEL from 'images/panel-quiz.png';
import Emoji from './Emoji';
import BtnAnswer from './BtnAnswer';
import { useState } from 'react';
import { v4 } from 'uuid';
import { useSelector } from 'react-redux';

export default function PanelQuestion({ id, question, answers, onSelected }) {
  const [selectedAnsw, setSelectedAnsw] = useState(null);
  const { dataHint } = useSelector((state) => state.hint);
  const handleSelect = (id, isTrue) => {
    setSelectedAnsw(id);
    // demo only
    if (isTrue) {
      onSelected(true);
    } else {
      onSelected(false);
    }
  };

  const renderAnswer = () => {
    const elm = answers.map((d, idx) => (
      <BtnAnswer
        key={v4()}
        index={idx}
        text={d.answerText}
        id={d.id}
        isSelected={selectedAnsw === d.id}
        isHint={dataHint.answerId === d.id}
        onClick={() => handleSelect(d.id, d.isTrue)}
      />
    ));

    return elm;
  };

  // const renderQuestionColor = () => {
  //   let strClr = { color: '#001965' };
  //   if (dataQuiz.questionId === 3) strClr = { color: '#00600F' };
  //   return strClr;
  // };

  // console.log({ startTimer });

  return (
    <div
      id={id}
      className='relative before:pt-[calc(491/360*100%)] before:block bg-cover max-w-[364px] mx-auto'
      style={{ backgroundImage: `url(${IMG_PANEL})` }}
    >
      <div className='absolute left-0 top-0 right-0 bottom-0 px-6'>
        <div className='relative -mt-5'>
          <Emoji />
        </div>
        <div className='sm:text-xl text-base font-extrabold text-[#001965] text-center leading-snug mt-6 mb-7 '>
          {question}
        </div>
        <div className='grid grid-cols-1 gap-2'>{renderAnswer()}</div>
      </div>
    </div>
  );
}

const { SET_HINT, RESET_HINT } = require('redux/types');

const initialHint = {
  quota: 1,
  answerId: 0,
};

const hintReducer = (state = { dataHint: initialHint }, { type, data }) => {
  switch (type) {
    case SET_HINT:
      return {
        ...state,
        dataHint: {
          quota: data !== 0 ? state.dataHint.quota - 1 : state.dataHint.quota,
          answerId: data,
        },
      };
    case RESET_HINT:
      return {
        ...state,
        dataHint: {
          quota: 1,
          answerId: 0,
        },
      };
    default:
      return state;
  }
};

export default hintReducer;
